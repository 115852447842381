import { Button2 } from '../../../Components/Button2';
import { Link } from '../../../Components/Link';
import { Section } from '../../../Components/Section';
import { Typography } from '../../../Components/Typography';
import CONSTANTS from '../../../Constants';
import Ellipse from '../../../Assets/Img/Home/Intellectual_Ellipse.webp';

import styles from './styles/part-of-the-future.module.scss';
import classNames from 'classnames';

export const PartOfTheFutureSection = () => {
    return (
        <Section>
            <div className={styles.container}>
                <div className={styles.grid}>
                    <div>
                        <Typography
                            variant="heading1"
                            component="h2"
                            style={{ color: '#ffffff', textAlign: 'center' }}
                        >
                            Part of the future
                        </Typography>
                        <Typography
                            variant="body1"
                            component="p"
                            style={{ color: '#ffffff', textAlign: 'center' }}
                        >
                            We are building the future of fashion, and we want you to be a part of
                            it.
                        </Typography>
                        <Button2 asChild variant="contained" color="primary">
                            <Link pathConfig={{ where: CONSTANTS.SCREEN.CAREERS }}>
                                Be Part of the Future
                            </Link>
                        </Button2>
                    </div>
                    <img
                        loading="lazy"
                        className={classNames(styles.ellipse, styles.right)}
                        src={Ellipse}
                        alt="logo"
                    />
                    <img
                        loading="lazy"
                        className={classNames(styles.ellipse, styles.left)}
                        src={Ellipse}
                        alt="logo"
                    />
                </div>
            </div>
        </Section>
    );
};
