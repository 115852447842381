import * as Accordion from '@radix-ui/react-accordion';
import { Section } from '../../../Components/Section';
import { Typography } from '../../../Components/Typography';
import placeholder from '../../../Assets/Img/Engineering/learning-placeholder.jpg';

import styles from './styles/learning-and-development.module.scss';
import { CheckCircle } from '@phosphor-icons/react';

const ITEMS = [
    {
        title: 'Cutting-edge Technology',
        description: 'Work with the latest AI tech, staying ahead of industry trends.',
    },

    {
        title: 'Research & Patents',
        description:
            'Lorem ipsum dolor sit amet. Non quaerat molestiae sit voluptates quia est voluptatum accusamus.',
    },
    {
        title: 'Learning Culture',
        description:
            'Lorem ipsum dolor sit amet. Non quaerat molestiae sit voluptates quia est voluptatum accusamus.',
    },
    {
        title: 'Career Progression',
        description:
            'Lorem ipsum dolor sit amet. Non quaerat molestiae sit voluptates quia est voluptatum accusamus.',
    },
    {
        title: 'Long-term Development',
        description:
            'Lorem ipsum dolor sit amet. Non quaerat molestiae sit voluptates quia est voluptatum accusamus.',
    },
    {
        title: 'Mentorship & Growth',
        description: 'Work with the latest AI tech, staying ahead of industry trends.',
    },
];

export const LearningAndDevelopmentSection = () => {
    return (
        <Section>
            <div className={styles.container}>
                <div className={styles.grid}>
                    <div className={styles.aside}>
                        <Typography variant="heading1" component="h2">
                            Learning & Development
                        </Typography>
                        <Accordion.Root
                            className={styles.accordionRoot}
                            type="single"
                            collapsible
                            defaultValue="item-0"
                        >
                            {ITEMS.map((item, index) => (
                                <Accordion.Item
                                    key={index}
                                    value={`item-${index}`}
                                    className={styles.accordionItem}
                                >
                                    <Accordion.Header className={styles.accordionHeader}>
                                        <Accordion.Trigger className={styles.accordionTrigger}>
                                            <CheckCircle fill="#2EB67D" size={20} weight="bold" />
                                            <Typography
                                                variant="body1"
                                                component="p"
                                                style={{ fontWeight: 700 }}
                                            >
                                                {item.title}
                                            </Typography>
                                        </Accordion.Trigger>
                                    </Accordion.Header>
                                    <Accordion.Content className={styles.accordionContent}>
                                        <Typography variant="body1" component="p">
                                            {item.description}
                                        </Typography>
                                    </Accordion.Content>
                                </Accordion.Item>
                            ))}
                        </Accordion.Root>
                    </div>
                    <div className={styles.right}>
                        <Typography variant="heading1" component="h2">
                            Learning & Development
                        </Typography>
                        <div className={styles.picture}>
                            <picture>
                                <img src={placeholder} className={styles.image} alt="placeholder" />
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
};
