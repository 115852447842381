// import FuturOfFashionSection from '../Home/Sections/FuturOfFashion';
import { BenefitsSection } from './components/BenefitsSection';
import { CultureSection } from './components/CultureSection';
import { HeroSection } from './components/HeroSection';
import { LearningAndDevelopmentSection } from './components/LearningAndDevelopmentSection';
import { PartOfTheFutureSection } from './components/PartOfTheFutureSection';

import styles from './index.module.scss';

export const EngineeringPage = () => {
    return (
        <main className={styles.main}>
            <HeroSection />
            <BenefitsSection />
            <CultureSection />
            <LearningAndDevelopmentSection />
            <PartOfTheFutureSection />
        </main>
    );
};
